import React, { Component } from 'react';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <Container
        fluid
        className={styles.container}
        style={{ backgroundImage: " url('img/logo_footer.png')" }}
      >
        <Row className="align-items-center mb-5">
          <Col
            sm="12"
            lg="4"
            className={'text-center text-lg-start mb-5 mb-lg-0'}
          >
            <img
              src={this.props.extraLayer + 'img/logo_header.png'}
              alt="wearecar"
              onClick={() => (window.location.href = '/')}
              style={{ cursor: 'pointer' }}
            />
          </Col>
          <Col
            sm="12"
            lg="4"
            className={'text-center text-lg-start mb-5 mb-lg-0'}
          >
            <div className={styles.title}>Company</div>
            <Link to={'/#come-funziona'} className={styles.link}>
              About
            </Link>
            <div className={styles.link}>
              <a
                rel={'noreferrer'}
                href={'https://semplicemedia.it/privacy-policy'}
                target={'_blank'}
                className={styles.link}
              >
                Privacy
              </a>
            </div>
            {/*<div className={styles.link}>Sede</div>*/}
          </Col>
          <Col
            sm="12"
            lg="4"
            className={'text-center text-lg-start mb-5 mb-lg-0'}
          >
            <div className={styles.title}>Office</div>
            <div className={styles.link}>
              <a href={'mailto:info@wearecar.it'}>info@wearecar.it</a>
            </div>
          </Col>
        </Row>
        <div className={styles.divider} />
        <Row>
          <Col className={styles.poweredBy}>
            Powered By: <b>Semplice Media S.r.l. a Socio Unico</b> - Società
            soggetta a Direzione e Coordinamento di Innova Semplice S.p.A.
            <br />
            Sede legale Via Medina, 40 - 80133 Napoli - P.IVA: IT08542331213, n.
            REA 966344 - Capitale Sociale: 100.000€ i.v.
          </Col>
        </Row>
      </Container>
    );
  }
}

Footer.defaultProps = {
  extraLayer: '',
};

export default Footer;
